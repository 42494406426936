<template>
  <div>
    <div>
      <div class="d-flex justify-center deep-purple--text my-5">
        <h4>Какую услугу Вы желаете получить?</h4>
      </div>
      <div v-if="categories.length && services.length" class="d-flex flex-wrap justify-space-evenly">
        <v-card
          v-for="category in categories"
          :key="category._id"
          max-width="344"
          class="mx-auto mb20 d-flex flex-column align-content-start"
          outlined
          elevation="3"
          @click="clickHandler(category)"
          rounded="xl"
        >
          <ImageForCardWithTitle
            :image="category.image"
            :title="category.title"
            main-theme-color="deep-purple"
          />

          <v-card-subtitle class="flex-grow-1 deep-purple--text">
            {{ category.description }}
          </v-card-subtitle>

          <v-card-subtitle
            v-if="category.services.length === 1"
            class="deep-purple--text font-weight-bold"
          >
            Цена: {{ category.services[0].price }} руб
          </v-card-subtitle>

        </v-card>
      </div>
    </div>
  </div>
</template>

<script>
import ImageForCardWithTitle from "@/components/ImageForCardWithTitle";

export default {
  name: "ChooseCategory",
  components: {ImageForCardWithTitle},
  data: () => ({
    categories: [],
    services: []
  }),
  computed: {},
  methods: {
    async checkWins() {
      const id = this.$store.getters.ownerId
      let json = JSON.stringify({id})

      const response = await fetch(`${this.$store.state.server}/record/check-window-list-for-client`, {
        method: "POST",
        headers: {
          "content-type": "application/json"
        },
        body: json
      })

      json = await response.json()
      if (json.empty) {
        await this.$router.push("/")
      }
    },
    async loadCategoryList() {
      const owner = this.$store.getters.ownerId

      const response = await fetch(`${this.$store.state.server}/category/load-category-list-for-client`, {
        method: 'POST',
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({owner})
      })

      const json = await response.json()
      if (json.message) {
        this.$store.commit('setServerMessage', json.message)
      }
      if (response.status === 200) {
        this.categories = json.categories.map(category => {
          category.services = []
          return category
        })
      }
    },

    async loadServiceListsForCategories() {
      const owner = this.$store.getters.ownerId

      const response = await fetch(`${this.$store.state.server}/service/load-service-list-for-client`, {
        method: 'POST',
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({owner})
      })

      const json = await response.json()
      if (json.message) {
        this.$store.commit('setServerMessage', json.message)
      }
      if (response.status === 200) {
        this.services = json.services
        this.categories.forEach(category => {
          for (let service of this.services) {
            if (service.category === category._id) {
              category.services.push(service)
            }
          }
        })
      }
    },

    clickHandler(category) {
      this.$store.commit("setCategory", category)
      if (category.services.length === 1) {
        this.$store.commit("setServices", category.services)
        this.$store.commit("setTotal", category.services[0].price)
        this.$router.push("/choose-datetime")
      } else {
        this.$router.push("/choose-service")
      }

    }
  },

  async mounted() {
    await this.checkWins()
    await this.loadCategoryList()
    await this.loadServiceListsForCategories()
  },
}
</script>

<style scoped>

</style>
